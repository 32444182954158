// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-fleet-js": () => import("./../../../src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-services-day-tours-js": () => import("./../../../src/pages/services/day-tours.js" /* webpackChunkName: "component---src-pages-services-day-tours-js" */),
  "component---src-pages-services-extended-tours-js": () => import("./../../../src/pages/services/extended-tours.js" /* webpackChunkName: "component---src-pages-services-extended-tours-js" */),
  "component---src-pages-services-tfnsw-js": () => import("./../../../src/pages/services/tfnsw.js" /* webpackChunkName: "component---src-pages-services-tfnsw-js" */),
  "component---src-pages-services-travel-club-js": () => import("./../../../src/pages/services/travel-club.js" /* webpackChunkName: "component---src-pages-services-travel-club-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

